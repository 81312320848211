.app__about {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.app__profiles {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.app__profile-item {
  width: 190px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  margin: 2rem;

  img {
    width: 100%;
    height: 170px;
    border-radius: 15px;
    object-fit: cover;
  }

  @media screen and (min-width: 2000px) {
    width: 370px;
    margin: 2rem 4rem;

    img {
      height: 320px;
    }
  }
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about .content h3 {
  font-weight: 700;
  font-size: 26px;
  color: #555555;
}
.about .content ul {
  list-style: none;
  padding: 0;
}
.about .content ul li {
  padding-bottom: 10px;
}
.about .content ul i {
  font-size: 20px;
  padding-right: 2px;
  color: #34b7a7;
}
.about .content p {
  margin-top: 2rem;
}
.about .content p:last-child {
  margin-bottom: 0;
}
.about .btns a {
  margin-top: 2rem;
  padding: 10px 36px;
  font-size: 20px;
  background-color: #313bac;
  color: #fff;
}
.about .btns .download {
  margin-left: 2rem;
}
.about .btns a:hover {
  background-color: #6b7688;
  color: #fff;
}

.about .content .skills ul li i {
  font-size: 10px;
}

@media only screen and (max-width: 767px) {
  .about .btns a {
    margin-top: 2rem;
    display: block;
    margin-left: 4rem;
    padding: 10px 36px;
    font-size: 20px;
    background-color: #313bac;
    color: #fff;
  }
  .about .btns .download {
    margin-left: 4rem;
  }
}
